import React from 'react'
import {UpdatedTable} from './UpdatedTable'

import './App.css'

class ServiceInstrumentation extends UpdatedTable {
  constructor(props) {
    super(props)
    
    this.state = 
      { defaultColDef: { resizable: true
                       , sortable: true
                       , cellStyle: this.right
                       }
      , columnDefs: [ { width: 120
                      , headerName: 'Service Name'
                      , field: 'serviceName'
                      , cellStyle: this.left
                      }
                    , { width: 50
                      , headerName: 'ID'
                      , field: 'serviceID'
                      }
                    , { width: 65
                      , headerName: 'Strat'
                      , field: 'strategy'
                      }
                    , { width: 160
                      , headerName: 'Last Updated'
                      , field: 'lastUpdated'
                      , valueFormatter: this.timeFormatter('lastUpdated')
                      }
                    , { width: 100
                      , headerName: 'Delta'
                      , field: 'instrumentation.mDelta'
                      , valueFormatter: this.fixedFormatter('instrumentation.mDelta', 3)
                      }
                    , { width: 100
                      , headerName: 'Gamma'
                      , field: 'instrumentation.mGamma'
                      , valueFormatter: this.fixedFormatter('instrumentation.mGamma', 3)
                      }
                    , { width: 100
                      , headerName: 'Theta'
                      , field: 'instrumentation.mTheta'
                      , valueFormatter: this.fixedFormatter('instrumentation.mTheta', 3)
                      }
                    , { width: 100
                      , headerName: 'Vega'
                      , field: 'instrumentation.mVega'
                      , valueFormatter: this.fixedFormatter('instrumentation.mVega', 3)
                      }
                    , { width: 100
                      , headerName: 'PnL'
                      , field: 'instrumentation.mPnl'
                      , valueFormatter: this.fixedFormatter('instrumentation.mPnl', 2)
                      }

                    , { width: 80
                      , headerName: 'GOF'
                      , field: 'gof'
                      , valueFormatter: this.fixedFormatter('gof', 2)
                      }

                    , { width: 80
                      , headerName: 'Min Vol'
                      , field: 'minVolume'
                      , valueFormatter: this.fixedFormatter('minVolume', 0)
                      }

                    , { width: 80
                      , headerName: 'Max Vol'
                      , field: 'maxVolume'
                      , valueFormatter: this.fixedFormatter('maxVolume', 0)
                      }

                    , { width: 80
                      , headerName: 'Max \u0393'  // Max Gamma
                      , field: 'maxGamma'
                      , valueFormatter: this.fixedFormatter('maxGamma', 2)
                      }

                    , { width: 80
                      , headerName: 'Max \u03BD'
                      , field: 'maxVega'
                      , valueFormatter: this.fixedFormatter('maxVega', 2)
                      }

                    , { width: 80
                      , headerName: 'Breach \u03BD' // Min Breach (nu for vega)
                      , field: 'minBreachVega'
                      , valueFormatter: this.fixedFormatter('minBreachVega', 2)
                      }

                    , { width: 80
                      , headerName: 'Pro Mul' 
                      , field: 'prorataMultiplier'
                      , valueFormatter: this.fixedFormatter('prorataMultiplier', 2)
                      }

                    , { width: 80
                      , headerName: 'Pro MVP' 
                      , field: 'prorataMaxVolumePercentage'
                      , valueFormatter: this.fixedFormatter('prorataMaxVolumePercentage', 2)
                      }
                     
                    ]
      }

    this.gengrid()
  }

  getRowId = (params) => {
    return `${params.data.serviceID}`
  }

  isExternalFilterPresent = () => true

  doesExternalFilterPass = params => {
    let d = params.data

    if (d.instrumentation) return true
    else                   return false
  }

  onGridReadyDerived = () => {
    let sortModel = [{colId: 'strategy', sort: 'asc', sortIndex: 0}, {colId: 'serviceID', sort: 'asc', sortIndex: 1}]
    this.api.applyColumnState({state: sortModel})
  }

  render() {
    let gridStyle = { width: this.totalWidth() }

    return (
      <div className="guif-div">
        <div className="ag-theme-balham-dark"
             style={gridStyle}>
          {this.grid}
        </div>
      </div>
    )
  }
}

export default ServiceInstrumentation
