import React from 'react'
import StraddleTable from './StraddleTable'
import PropTypes from 'prop-types'

import './App.css'

class StraddleOptionTable extends StraddleTable {
  constructor(props) {
    super(props)

    if (props.pctr) {
      this.dataStore = props.pctr.dataStore
    } else {
      this.dataStore = props.dataStore
    }

    this.appWide = this.dataStore.appWide
    this.height = props.height

    this.ldMap = {}
    this.dataStore.ldList.forEach(ld => {
      this.ldMap[ld.back] = ld
    })

    this.updateListeners = {}
    this.secParams = {}
    this.valuationIDs = {}
    for (let back in this.ldMap) {
      this.valuationIDs[back] = 'N/A'
      this.secParams[back] = {}
      this.updateConfig(back, this.ldMap[back].getConfig())
      this.updateListeners[back] = data => this.update(data, back)
    }

    let combinedCreditGetter = () => {
      let callCredit = this.getCredit('call')
      let putCredit = this.getCredit('put')
      return function(params) {
        let cc = callCredit(params)
        let pc = putCredit(params)
        return Math.max(cc, pc)
      }
    }

    let combinedPositionGetter = function(params) {
      return params.data.callPosition + params.data.putPosition
    }

    this.cols = { 'Expiry' :
                  { width: 90
                  , headerName: 'Expiry'
                  , field: 'expiration'
                  , filter: 'agNumberColumnFilter'
                  , cellStyle: this.expiryStyle
                  }
                , 'vtexp' :
                  { width: 70
                  , headerName: 'vtexp'
                  , field: 'callVtexp'
                  , valueFormatter: this.strippedNumber(4, false, 4)
                  }                
                , 'Call IV' :
                  { width: 70
                  , headerName: 'IV'
                  , field: 'callIV'
                  , valueFormatter: this.strippedNumber(4, false, 4)
                  }
                , 'Call Delta' : 
                  { width: 70
                  , headerName: 'CDelta'
                  , field: 'callDelta'
                  , valueFormatter: this.strippedNumber()
                  , filter: 'agNumberColumnFilter'
                  }
                , 'Call Gamma' :
                  { width: 70
                  , headerName: 'CGamma'
                  , field: 'callGamma'
                  , valueFormatter: this.strippedNumber()
                  }
                , 'Call Vega' : 
                  { width: 70
                  , headerName: 'Vega'
                  , field: 'callVega'
                  , valueFormatter: this.strippedNumber()
                  }
                , 'Own Call mBQ' :
                  { width: 80
                  , headerName: 'oBQ'
                  , field: 'callOwnBidVolume'
                  , valueFormatter: this.strippedNumber(0, true)
                  , cellStyle: {textAlign: 'right'}
                  }
                , 'Call mBQ' :
                  { width: 80
                  , headerName: 'mBQ'
                  , field: 'callMarketBidVolume'
                  , valueFormatter: this.strippedNumber(0, true)
                  , cellStyle: {textAlign: 'right'}
                  }
                , 'Call mBPx' :
                  { width: 70
                  , headerName: 'mBPx'
                  , field: 'callMarketBidPx'
                  , valueFormatter: this.strippedNumber(-1, true, 5)
                  , cellStyle: this.quoteStyle('call', 'Bid', true)
                  }
                , 'Call Theo' :
                  { width: 80
                  , headerName: 'Theo'
                  , field: 'callAdjTheo'
                  , valueFormatter: this.strippedNumber(-2)
                  , cellStyle: this.theoStyle()
                  }
                , 'Call mAPx' : 
                  { width: 70
                  , headerName: 'mAPx'
                  , field: 'callMarketAskPx'
                  , valueFormatter: this.strippedNumber(-1, true, 5)
                  , cellStyle: this.quoteStyle('call', 'Ask', true)
                  }
                , 'Call mAQ' : 
                  { width: 80
                  , headerName: 'mAQ'
                  , field: 'callMarketAskVolume'
                  , valueFormatter: this.strippedNumber(0, true)
                  , cellStyle: {textAlign: 'left'}
                  }
                , 'Own Call mAQ' :
                  { width: 80
                  , headerName: 'oAQ'
                  , field: 'callOwnAskVolume'
                  , valueFormatter: this.strippedNumber(0, true)
                  , cellStyle: {textAlign: 'right'}
                  }
                , 'Call B.Err' : 
                  { width: 70
                  , headerName: 'B.Err'
                  , field: 'callDerivedValuationBuyError'
                  , valueFormatter: this.strippedNumber()
                  , cellStyle: this.errStyle('callDerivedValuationBuyError')
                  }
                , 'Call S.Err' : 
                  { width: 70
                  , headerName: 'S.Err'
                  , field: 'callDerivedValuationSellError'
                  , valueFormatter: this.strippedNumber()
                  , cellStyle: this.errStyle('callDerivedValuationSellError')
                  }
                , 'Call Alpha' :
                  { width: 70
                  , headerName: 'Alpha'
                  , field: 'callPriceAlpha'
                  , valueFormatter: this.strippedNumber(3, true)
                  , cellStyle: this.adjustmentStyle('callPriceAlpha')
                  }
                , 'Call mVol' :
                  { width: 90
                  , headerName: 'mVol'
                  , field: 'callMarketTradedVolume'
                  , valueFormatter: this.strippedNumber(0, true)
                  , cellStyle: {textAlign: 'right'}
                  }
                , 'Call Enabled' : 
                  { width: 80
                  , headerName: 'CEnabled'
                  , valueGetter: this.renderEnabled('call') 
                  , cellStyle: this.enableStyle('callAdjustFlatten')
                  }
                , 'Call APC' : 
                  { width: 70
                  , headerName: 'APC'
                  , field: 'callAPC'
                  , valueFormatter: this.exponentialFormatter('callAPC', 1)
                  }
                , 'Call Adj' : 
                  { width: 80
                  , headerName: 'Adj'
                  , field: 'callAdjustment'
                  , valueFormatter: this.strippedNumber()
                  , cellStyle: this.adjustmentStyle('callAdjustment')
                  }
                , 'Call AdjCap %' :
                  { width: 80
                  , headerName: 'AdjCap %'
                  , field: 'callAdjFractionOfCap'
                  , cellStyle: this.adjFracOfCapStyle('call')
                  , valueFormatter: this.percentFormatter
                  }
                , 'CPos' : 
                  { width: 80
                  , headerName: 'CPos'
                  , field: 'callPosition'
                  , valueFormatter: this.strippedNumber(0, true)
                  , cellStyle: this.positionStyle('callPosition', 'callFlatten')
                  }
                , 'Call Display' : 
                  { width: 120
                  , headerName: 'Display'
                  , field: 'callDisplay'
                  , cellStyle: this.marketStatusStyle('call')
                  }
                , 'Strike' : 
                  { width: 80
                  , headerName: 'Strike'
                  , field: 'strike'
                  , cellStyle: this.strikeStyle
                  , filter: 'agNumberColumnFilter'
                  }
                , 'TPos' : 
                  { width: 80
                  , headerName: 'TPos'
                  , field: 'combinedPosition'
                  , valueGetter: combinedPositionGetter
                  , valueFormatter: this.strippedNumber(0, true)
                  , cellStyle: this.positionStyle(combinedPositionGetter, 'callFlatten', true)
                  }
                , 'Credit' :
                  { width: 55
                  , headerName: 'Credit'
                  , field: 'combinedCredit'
                  , valueGetter: combinedCreditGetter()
                  , valueFormatter: this.strippedNumber(3, true)
                  , cellStyle: this.creditStyle()
                  }
                , 'PPos' : 
                  { width: 80
                  , headerName: 'PPos'
                  , field: 'putPosition'
                  , valueFormatter: this.strippedNumber(0, true)
                  , cellStyle: this.positionStyle('putPosition', 'putFlatten')
                  }
                , 'Put Adj' : 
                  { width: 80
                  , headerName: 'Adj'
                  , field: 'putAdjustment'
                  , valueFormatter: this.strippedNumber()
                  , cellStyle: this.adjustmentStyle('putAdjustment')
                  }
                , 'Put AdjCap %' :
                  { width: 80
                  , headerName: 'AdjCap %'
                  , field: 'putAdjFractionOfCap'
                  , cellStyle: this.adjFracOfCapStyle('put')
                  , valueFormatter: this.percentFormatter
                  }
                , 'Put APC' : 
                  { width: 70
                  , headerName: 'APC'
                  , field: 'putAPC'
                  , valueFormatter: this.exponentialFormatter('putAPC', 1)
                  }
                , 'Own Put mBQ' :
                  { width: 80
                  , headerName: 'oBQ'
                  , field: 'putOwnBidVolume'
                  , valueFormatter: this.strippedNumber(0, true)
                  , cellStyle: {textAlign: 'right'}
                  }
                , 'Put mBQ' : 
                  { width: 80
                  , headerName: 'mBQ'
                  , field: 'putMarketBidVolume'
                  , valueFormatter: this.strippedNumber(0, true)
                  , cellStyle: {textAlign: 'right'}
                  }
                , 'Put mBPx' : 
                  { width: 70
                  , headerName: 'mBPx'
                  , field: 'putMarketBidPx'
                  , valueFormatter: this.strippedNumber(-1, true, 5)
                  , cellStyle: this.quoteStyle('put', 'Bid', true)
                  }
                , 'Put Theo' : 
                  { width: 80
                  , headerName: 'Theo'
                  , field: 'putAdjTheo'
                  , valueFormatter: this.strippedNumber(-2)
                  , cellStyle: this.theoStyle()
                  }
                , 'Put mAPx' : 
                  { width: 70
                  , headerName: 'mAPx'
                  , field: 'putMarketAskPx'
                  , valueFormatter: this.strippedNumber(-1, true, 5)
                  , cellStyle: this.quoteStyle('put', 'Ask', true)
                  }
                , 'Put mAQ' : 
                  { width: 80
                  , headerName: 'mAQ'
                  , field: 'putMarketAskVolume'
                  , valueFormatter: this.strippedNumber(0, true)
                  , cellStyle: {textAlign: 'left'}
                  }
                , 'Own Put mAQ' :
                  { width: 80
                  , headerName: 'oAQ'
                  , field: 'putOwnAskVolume'
                  , valueFormatter: this.strippedNumber(0, true)
                  , cellStyle: {textAlign: 'right'}
                  }
                , 'Put B.Err' : 
                  { width: 70
                  , headerName: 'B.Err'
                  , field: 'putDerivedValuationBuyError'
                  , valueFormatter: this.strippedNumber()
                  , cellStyle: this.errStyle('putDerivedValuationBuyError')
                  }
                , 'Put S.Err' : 
                  { width: 70
                  , headerName: 'S.Err'
                  , field: 'putDerivedValuationSellError'
                  , valueFormatter: this.strippedNumber()
                  , cellStyle: this.errStyle('putDerivedValuationSellError')
                  }
                , 'Put Alpha' :
                  { width: 70
                  , headerName: 'Alpha'
                  , field: 'putPriceAlpha'
                  , valueFormatter: this.strippedNumber(3, true)
                  , cellStyle: this.adjustmentStyle('putPriceAlpha')
                  }
                , 'Put mVol' :
                  { width: 90
                  , headerName: 'mVol'
                  , field: 'putMarketTradedVolume'
                  , valueFormatter: this.strippedNumber(0, true)
                  , cellStyle: {textAlign: 'right'}
                  }
                , 'Put Enabled' : 
                  { width: 80
                  , headerName: 'PEnabled'
                  , valueGetter: this.renderEnabled('put') 
                  , cellStyle: this.enableStyle('putAdjustFlatten')
                  }
                , 'Call Settle' :
                  { width: 70
                  , headerName: 'mStl'
                  , field: 'callMarketSettlementPx'
                  , valueFormatter: this.strippedNumber(-1)
                  , cellStyle: {textAlign: 'left'}
                  }
                , 'Put Settle' :
                  { width: 70
                  , headerName: 'mStl'
                  , field: 'putMarketSettlementPx'
                  , valueFormatter: this.strippedNumber(-1)
                  , cellStyle: {textAlign: 'left'}
                  }
                , 'Call SId' : 
                  { width: 70
                  , headerName: 'Call SId'
                  , field: 'callSecurityId'
                  }
                , 'Put SId' : 
                  { width: 70
                  , headerName: 'Put SId'
                  , field: 'putSecurityId'
                  }
                , 'Own Call mBPx' :
                  { width: 70
                  , headerName: 'oBPx'
                  , field: 'callOwnBidPx'
                  , valueFormatter: this.strippedNumber(-1, true)
                  , cellStyle: this.quoteStyle('call', 'Bid', false)
                  }
                , 'Own Call mAPx' :
                  { width: 70
                  , headerName: 'oAPx'
                  , field: 'callOwnAskPx'
                  , valueFormatter: this.strippedNumber(-1, true)
                  , cellStyle: this.quoteStyle('call', 'Ask', false)
                  }
                , 'Own Put mBPx' :
                  { width: 70
                  , headerName: 'oBPx'
                  , field: 'putOwnBidPx'
                  , valueFormatter: this.strippedNumber(-1, true)
                  , cellStyle: this.quoteStyle('put', 'Bid', false)
                  }
                , 'Own Put mAPx' :
                  { width: 70
                  , headerName: 'oAPx'
                  , field: 'putOwnAskPx'
                  , valueFormatter: this.strippedNumber(-1, true)
                  , cellStyle: this.quoteStyle('put', 'Ask', false)
                  }
                , 'Put Delta' : 
                  { width: 70
                  , headerName: 'PDelta'
                  , field: 'putDelta'
                  , valueFormatter: this.strippedNumber()
                  , filter: 'agNumberColumnFilter'
                  }
                , 'Put Gamma' : 
                  { width: 70
                  , headerName: 'PGamma'
                  , field: 'putGamma'
                  , valueFormatter: this.strippedNumber()
                  }
                , 'Put Vega' : 
                  { width: 70
                  , headerName: 'Vega'
                  , field: 'putVega'
                  , valueFormatter: this.strippedNumber()
                  }
                , 'Put IV' : 
                  { width: 70
                  , headerName: 'IV'
                  , field: 'putIV'
                  , valueFormatter: this.strippedNumber(4, false, 4)
                  } 
                , 'Put Display' : 
                  { width: 120
                  , headerName: 'Display'
                  , field: 'putDisplay'
                  , cellStyle: this.marketStatusStyle('put')
                  }
                }

    let modeValue = this.appWide.mode[this.title]
    let columns = modeValue === 'full' ? this.fullColumns() : this.overviewColumns()
    this.state = 
      { defaultColDef: { resizable: true
                       , sortable: true
                       , cellStyle: this.right
                       }
      , columnDefs: columns
      , filter: (props.filter === 'through-market') ? this.throughMarketFilter : this.defaultRowFilter
      , showAllRows: this.appWide.allContracts[this.title]
      }

    this.state.mode = this.appWide.mode
    this.state.allContracts = this.appWide.allContracts

    this.redraw([ 'callMarketBidPx', 'putMarketBidPx', 'callMarketAskPx', 'putMarketAskPx'
                , 'callOwnBidPx', 'putOwnBidPx', 'callOwnAskPx', 'putOwnAskPx'
                , 'callDerivedValuationBuyError', 'putDerivedValuationBuyError'
                , 'callDerivedValuationSellError', 'putDerivedValuationSellError'
                , 'callAdjustment', 'putAdjustment', 'strike'
                , 'callPosition', 'putPosition', 'combinedPosition', 'combinedCredit'])
    this.gengrid()
  }

  fullColumns = () => {
    return [ this.cols['Expiry']
           , this.cols['vtexp']
           , this.cols['Call IV']
           , this.cols['Call Alpha']
           , this.cols['Call Delta']
           , this.cols['Call Vega']
           , this.cols['Own Call mBQ']
           , this.cols['Own Call mBPx']
           , this.cols['Call mBQ']
           , this.cols['Call mBPx']
           , this.cols['Call Theo']
           , this.cols['Call mAPx']
           , this.cols['Call mAQ']
           , this.cols['Own Call mAPx']
           , this.cols['Own Call mAQ']
           , this.cols['Call B.Err']
           , this.cols['Call S.Err']
           , this.cols['Call mVol']
           , this.cols['Call APC']
           , this.cols['Call Adj']
           , this.cols['Call AdjCap %']
           , this.cols['Credit']
           , this.cols['Call Display']
           , this.cols['Strike']
           , this.cols['TPos']
           , this.cols['CPos']
           , this.cols['PPos']
           , this.cols['Put Adj']
           , this.cols['Put AdjCap %']
           , this.cols['Put APC']
           , this.cols['Own Put mBQ']
           , this.cols['Own Put mBPx']
           , this.cols['Put mBQ']
           , this.cols['Put mBPx']
           , this.cols['Put Theo']
           , this.cols['Put mAPx']
           , this.cols['Put mAQ']
           , this.cols['Own Put mAPx']
           , this.cols['Own Put mAQ']
           , this.cols['Put B.Err']
           , this.cols['Put S.Err']
           , this.cols['Put Delta'] 
           , this.cols['Put Vega']
           , this.cols['Put mVol']
           , this.cols['Call Settle']
           , this.cols['Put Settle']
           , this.cols['Call SId']
           , this.cols['Put SId']
           , this.cols['Call Enabled']
           , this.cols['Put Enabled']
           , this.cols['Call Gamma']                 
           , this.cols['Put Gamma']
           , this.cols['Put IV']
           , this.cols['Put Alpha']
           , this.cols['Put Display']
           , this.cols['Strike']
           , this.cols['Expiry']
           ]
  }

  overviewColumns = () => {
    return [ this.cols['Expiry']
           , this.cols['Call B.Err']
           , this.cols['Own Call mBQ']
           , this.cols['Own Call mBPx']
           , this.cols['Call mBQ']
           , this.cols['Call mBPx']
           , this.cols['Call Theo']
           , this.cols['Call mAPx']
           , this.cols['Call mAQ']
           , this.cols['Own Call mAPx']
           , this.cols['Own Call mAQ']
           , this.cols['Call S.Err']
           , this.cols['Call Adj']
           , this.cols['CPos']
           , this.cols['Strike']
           , this.cols['PPos']
           , this.cols['Put Adj']
           , this.cols['Put B.Err']
           , this.cols['Own Put mBQ']
           , this.cols['Own Put mBPx']
           , this.cols['Put mBQ']
           , this.cols['Put mBPx']
           , this.cols['Put Theo']
           , this.cols['Put mAPx']
           , this.cols['Put mAQ']
           , this.cols['Own Put mAPx']
           , this.cols['Own Put mAQ']
           , this.cols['Put S.Err']
           ]
  }

  update = (message, back, batchUpdate = true) => {
    if (message !== undefined) {
      this.valuationIDs[back] = message.valuationId
      let valuationID = ''
      for (let bback in this.valuationIDs) {
        if (valuationID !== '') valuationID += ', '
        if (bback && bback !== 'stand-alone') valuationID += bback + ':'
        valuationID += this.valuationIDs[bback]
      }
      this.setState({valuationId: valuationID})
      
      let rowData = message.rows
      let secParams = {}

      this.closestAtm = {}
      this.closestAtmId = {}

      this.expiries = []

      for (let index in rowData) {
        rowData[index].back = back
        let expiry = rowData[index].expiration
        let expiryByDisplay = rowData[index].expiration + ' ' + rowData[index].dispCode
        let key = rowData[index].dispCode + ' ' 
            + rowData[index].strike + ' ' 
            + expiry

        secParams[key] = rowData[index]

        // workout id of closest to atm so we can highlight
        let vegaDiff = rowData[index].callVega
        if ((this.closestAtm[expiryByDisplay] === undefined) || (vegaDiff > this.closestAtm[expiryByDisplay])) {
          this.closestAtm[expiryByDisplay] = vegaDiff
          this.closestAtmId[expiryByDisplay] = this.getRowId({data: rowData[index]})
        }

        if (!this.expiries.includes(expiry)) {
          this.expiries.push(expiry)
        }
      }
      
      let adds = []
      let updates = []
      if (this.api !== undefined && !this.api.isDestroyed()) {
        for (let id in secParams) {
          if (id in this.secParams[back]) {
            let updateItem = secParams[id]
            updates.push(updateItem)
          } else {
            let newItem = secParams[id]
            adds.push(newItem)
          }
          this.secParams[back][id] = secParams[id]
        }
      }

      for (let back in this.ldMap) {
        this.updateConfig(back, this.ldMap[back].getConfig())
      }

      if (this.api !== undefined && !this.api.isDestroyed()) {
        if (batchUpdate) {
          this.api.applyTransactionAsync({
            add: adds
            , update: updates
          })
        }
        else {
          this.api.applyTransaction({
            add: adds
            , update: updates
          })
        }
      }
    }
  }
  
  componentWillUnmount() {
    for (let back in this.ldMap) {
      this.ldMap[back].removeListener('straddle-options-sec-params', this.updateListeners[back])
    }

    super.componentWillUnmount()
  }

  getRowId = (params) => {
    return params.data.dispCode + ' ' 
      + params.data.strike + ' ' 
      + params.data.expiration
  }

  getRowStyle = (params) => {
    let expiryByDisplay = params.data.expiration + ' ' + params.data.dispCode

    if ((this.closestAtmId[expiryByDisplay] !== undefined)
        && (this.closestAtmId[expiryByDisplay] === this.getRowId(params))) {
      return {backgroundColor: '#775555'}
    } else {
      return {backgroundColor: '#262c2e'}
    }
  }

  onGridReady = (params) => {
    this.api = params.api

    let sortModel = [{colId: 'expiration', sort: 'asc'} , {colId: 'callDisplay', sort: 'asc'} , {colId: 'strike', sort: 'asc'}]
    this.api.applyColumnState({state: sortModel})

    for (let back in this.ldMap) {
      this.updateListeners[back](this.ldMap[back]['straddle-options-sec-params'])
    }

    for (let back in this.ldMap) {
      // After full update, add listeners
      this.ldMap[back].addListener('straddle-options-sec-params', this.updateListeners[back])
    }
  }

  isExternalFilterPresent = () => {
    return true
  }

  quickFilterOnInput = (event) => {
    this.api.setQuickFilter(event.target.value)
  }

  onModeChange = () => {
    let modeName = 'mode ' + this.title
    let value = document.querySelector(`input[name="${modeName}"]:checked`).value

    let columns = value === 'full' ? this.fullColumns() : this.overviewColumns()

    this.appWide.mode[this.title] = value
    this.secParams = {}
    this.dataStore.ldList.forEach(ld => this.secParams[ld.back] = {})

    this.setState({ columnDefs: columns
                  }, () =>
    {
      console.log('now regening grid')
      delete this.api
      this.gengrid()
      this.setState({mode: this.appWide.mode})
    })
  }

  renderModeRadio = () => {
    let labelStyle = { fontSize: '14px' }
    let modeName = 'mode ' + this.title

    let fullChecked = this.state.mode[this.title] === 'full'
    let overviewChecked = this.state.mode[this.title] === 'overview'
    return (
      <div style={{width: '150px'}}>
        <div style={{float: 'right'}}>
          <input type="radio"
                 name={modeName}
                 id="full" value="full"
                 checked={fullChecked}
                 onChange={this.onModeChange}>
          </input>
          <label style={labelStyle}>Full</label>

          <input type="radio"
                 name={modeName}
                 id="overview"
                 value="overview"
                 checked={overviewChecked}
                 onChange={this.onModeChange}>
          </input>
          <label style={labelStyle}>Overview</label>
        </div>
      </div>
    )
  }

  onAllContractsChange = () => {
    let acName = 'showall ' + this.title
    let value = document.querySelector(`input[name="${acName}"]`).checked

    this.appWide.allContracts[this.title] = value
    this.setState({showAllRows: value},
                  () =>
                  {
                    console.log('this.state.showAllRows = ' + this.state.showAllRows + '  ' + value)
                  })
  }

  renderAllContracts = () => {
    if (this.props.filter === 'through-market') return null

    let labelStyle = { fontSize: '14px' }
    let acName = 'showall ' + this.title

    return (
      <div style={{width: '200px'}}>
        <div style={{float: 'right'}}>
          <label style={labelStyle}>Show All Contracts</label>
          <input type="checkbox" name={acName} onChange={this.onAllContractsChange} defaultChecked={this.state.showAllRows}>
          </input>
        </div>
      </div>
    )
  }
}

StraddleOptionTable.propTypes = 
  { filter: PropTypes.string
  , dataStore: PropTypes.object
  }

export default StraddleOptionTable
